/**
 * Created by Vladyslav Dubyna 01/29/24
 *
 * this is a hook to return values from calculator library. it's using mobx store to get props
 */
import { Calculator, ProductResultType, ModuleAreaForm } from 'bsh-calculator';

import useStore from './useStore';
import { getSummaryWidgetDataSection, getWidgetDataSection, TWidgetSection } from '../components/calculator-widget/utils';
import pageUrl from '../const/pages';
import {
  getBlackoutBoxWidgetData,
  getHotWaterWidgetData,
  getMemoryExtensionWidgetData,
  getPvWidgetData,
  getStorageExtensionData,
  getSummaryWidgetData,
  getWallboxWidgetData,
  validateProductProps,
} from '../helpers/widget';

export const useCalculator = () => {
  const { dataStore, cacheStore, userStore, photovoltaikStore, financingStore, memoryExtensionStore } = useStore();
  const switchers = dataStore.formSwitchers;
  const possiblePaths = switchers.filter((switcher) => switcher.checked);

  // get all forms
  const { hotWaterForm, blackoutBoxSelectionId, wallboxForm, storageExtensionForm, internalObjectAddress } = dataStore;

  const { memoryExtensionForm, numberSummaryBatteries } = memoryExtensionStore;

  const {
    moduleAreaForms,
    modulTypeId,
    photovoltaikExtrasForm,
    summaryModuleAreaForm,
    profitabilityCalculationForm,
    storagesId,
    imageRoofPlan,
  } = photovoltaikStore;

  // summary modules form
  const { summaryNumberModules, pvPlannedSize, averageYield, inverterPower, moduleArea } = summaryModuleAreaForm;

  // TODO ask about first only parameters
  const { moduleAreaLabel, moduleAreaProduction, moduleAreaTypes, moduleAreaDirectionId, moduleAreaSize } = moduleAreaForms[0] || {};

  // hot water pump
  const { selectionHotWaterHeatPumpId, centralHeatingExist } = hotWaterForm;

  // memory extension form
  const { numberExistingBatteries, numberAdditionalBattery, memoryExtensionSelectionId } = memoryExtensionForm;

  // wallbox form
  const {
    selectionSteleSenecPremium,
    selectionSteleSenecPlus,
    steleSenecPremiumQuantity,
    steleSenecPlusQuantity,
    wallboxQuantity,
    wallboxSelectionId,
    selectionJuiceBooster2,
    juiceBooster2Quantity,
    chargingCable,
    chargingCableQuantity,
  } = wallboxForm;

  const { electricityConsumption, powerConsumption, grossElectricityCosts } = profitabilityCalculationForm;

  // storage extension form
  const { storageExtensionSelectionId } = storageExtensionForm;

  // photovoltaik form
  const {
    newCounterBox,
    moveSat,
    withoutZaehlerhelden,
    privatCounterBox,
    privatCounterBoxNumber,
    moveRoofSteps,
    fem111SmartCounter,
    fem111SmartCounterNumber,
    femAppPowerHeat,
    enerPlug,
    enerPlugNumber,
    enerPlugSetNumber,
    drohn,
    birdProtection,
    senecHeat,
  } = photovoltaikExtrasForm;

  const {
    paymentSelection,
    financingVia,
    financingDownPayment,
    financingDownPaymentWp,
    financingInterestRate,
    financingTerm,
    financingTermWp,
    financingTermWallbox,
    financingTermBlackoutBox,
    financingTermMemoryExtension,
    financingTermStorageExtension,
    financingRateNet,
    financingRateGross,
    leasingDownPayment,
    leasingTerm,
    maximumLeasingValue,
    leasingRateNet,
    leasingRestValue,
    leasingCommission,
    leasingInterestRate,
    hirePurchaseDownPayment,
    rentingRate,
    rentingCommission,
    rentingInterestRate,
    solarSpecial,
    bshEstate,
    offerUnitPrices,
    summaryPriceNetWithSolarSpecial,
    discountInEurosSolarSpecial,
    discountInPercent,
    discountInEuros,
    discountIndividualInEuros,
    discountIndividualSelected,
    estateDescription,
    summaryPriceNetWithDiscount,
    hirePurchaseDownPaymentWp,
    hirePurchaseTerm,
    hirePurchaseTermWp,
    hirePurchaseTermWallbox,
    hirePurchaseTermBlackoutBox,
    hirePurchaseTermMemoryExtension,
    hirePurchaseTermStorageExtension,
    leasingDownPaymentWp,
    leasingTermWp,
    leasingTermWallbox,
    leasingTermBlackoutBox,
    leasingTermMemoryExtension,
    leasingTermStorageExtension,
  } = financingStore.financingForm;

  const { featuresOptions } = financingStore;

  /**
   * process widget data calculation
   */
  const calculationValues = () => {
    const calculator = new Calculator(
      Array.from(cacheStore.products.values()),
      Array.from(cacheStore.brands.values()),
      userStore.customer.type,
    );
    const isFlatRoof = moduleAreaForms.some((form) => form.moduleAreaTypes.includes('montage_unterkonstruktion_fuer_flachdach_ost_west'));

    const newModuleAreaForms = JSON.parse(JSON.stringify(moduleAreaForms));

    if (isFlatRoof) {
      const extraModuleArea: ModuleAreaForm = {
        id: '',
        moduleAreaLabel: '',
        numbersOfModules: 0,
        moduleAreaProduction: 0,
        moduleAreaSize: 0,
        moduleAreaTypes: ['default'],
        moduleAreaDirectionId: undefined,
      };

      newModuleAreaForms.push(extraModuleArea);
    }

    // left area checkboxes values (active forms)
    const shouldShowWallbox = !!possiblePaths.find((item) => item.url === pageUrl.wallboxQoute)?.checked;
    const shouldShowHotWater = !!possiblePaths.find((item) => item.url === pageUrl.hotWaterPump)?.checked;
    const shouldShowStorageExtension = !!possiblePaths.find((item) => item.url === pageUrl.storageExtension)?.checked;
    const shouldShowBlackoutBox = !!possiblePaths.find((item) => item.url === pageUrl.blackoutBox)?.checked;
    const shouldShowMemoryExtension = !!possiblePaths.find((item) => item.url === pageUrl.memoryExtension)?.checked;
    const shouldShowFinancing = !!possiblePaths.find((item) => item.url === pageUrl.financing)?.checked;
    const shouldShowPhovoltaik = !!possiblePaths.find((item) => item.url === pageUrl.photovoltaikQuote)?.checked;

    // all props to calculate products
    const productsProps = {
      electricityConsumption,
      powerConsumption,
      grossElectricityCosts,
      possiblePaths,
      paymentSelection,
      financingVia,
      hirePurchaseDownPayment: Number(hirePurchaseDownPayment),
      hirePurchaseDownPaymentWp: Number(hirePurchaseDownPaymentWp),
      hirePurchaseTerm: Number(hirePurchaseTerm),
      hirePurchaseTermWp: Number(hirePurchaseTermWp),
      hirePurchaseTermWallbox: Number(hirePurchaseTermWallbox),
      hirePurchaseTermBlackoutBox: Number(hirePurchaseTermBlackoutBox),
      hirePurchaseTermMemoryExtension: Number(hirePurchaseTermMemoryExtension),
      hirePurchaseTermStorageExtension: Number(hirePurchaseTermStorageExtension),
      leasingDownPayment: Number(leasingDownPayment),
      leasingDownPaymentWp: shouldShowHotWater && selectionHotWaterHeatPumpId ? Number(leasingDownPaymentWp) : 0,
      leasingTerm: Number(leasingTerm),
      leasingTermWp: Number(leasingTermWp),
      leasingTermWallbox: Number(leasingTermWallbox),
      leasingTermBlackoutBox: Number(leasingTermBlackoutBox),
      leasingTermMemoryExtension: Number(leasingTermMemoryExtension),
      leasingTermStorageExtension: Number(leasingTermStorageExtension),
      financingDownPayment: Number(financingDownPayment),
      financingDownPaymentWp: Number(financingDownPaymentWp),
      financingInterestRate: Number(financingInterestRate),
      financingTerm: Number(financingTerm),
      financingTermWp: Number(financingTermWp),
      financingTermWallbox: Number(financingTermWallbox),
      financingTermBlackoutBox: Number(financingTermBlackoutBox),
      financingTermMemoryExtension: Number(financingTermMemoryExtension),
      financingTermStorageExtension: Number(financingTermStorageExtension),
      financingRateNet,
      financingRateGross,
      maximumLeasingValue,
      leasingRateNet,
      leasingRestValue,
      leasingCommission,
      leasingInterestRate,
      rentingRate,
      rentingCommission,
      rentingInterestRate,
      solarSpecial,
      bshEstate,
      offerUnitPrices,
      summaryPriceNetWithSolarSpecial,
      discountInEurosSolarSpecial,
      discountInPercent,
      discountInEuros,
      estateDescription,
      summaryPriceNetWithDiscount,
      discountIndividualInEuros,
      discountIndividualSelected,
      selectionSteleSenecPremium,
      selectionSteleSenecPlus,
      steleSenecPremiumQuantity,
      steleSenecPlusQuantity,
      wallboxQuantity,
      chargingCable,
      chargingCableQuantity,
      wallboxSelection: wallboxSelectionId ?? '',
      selectionJuiceBooster2,
      juiceBooster2Quantity,
      newCounterBox,
      pvPlannedSize,
      withoutZaehlerhelden,
      selectionHotWaterHeatPump: selectionHotWaterHeatPumpId ?? '',
      centralHeatingExist,
      storageCalculated: false,
      moveSat,
      privatCounterBox,
      privatCounterBoxNumber,
      moveRoofSteps,
      fem111SmartCounter,
      fem111SmartCounterNumber,
      femAppPowerHeat,
      enerPlug,
      enerPlugNumber,
      enerPlugSetNumber,
      drohn,
      storageSelected: true,
      averageYield,
      inverterPower: inverterPower || 1,
      moduleArea,
      moduleAreaLabel,
      storageExtensionSelection: storageExtensionSelectionId ?? '',
      numbersOfModules: summaryNumberModules ?? 0,
      moduleAreaProduction,
      moduleAreaSize: moduleAreaSize ?? 0,
      summaryNumberModules,
      modulType: modulTypeId ?? undefined,
      storages: storagesId ?? undefined,
      storageBrand: undefined,
      moduleAreaTypes: moduleAreaTypes || '',
      moduleAreaForms: newModuleAreaForms as ModuleAreaForm[],
      moduleAreaDirection: moduleAreaDirectionId || '',
      generationProfile: 0,
      loadProfile: 0,
      pvPerformance: 0,
      quoteNumber: '',
      summaryPriceGross: 0,
      summaryPriceNet: 0,
      usableStorage: 0,
      yearlyElectricityTraffic: 0,
      blackoutBoxSelection: blackoutBoxSelectionId ?? undefined,
      memoryExtensionSelection: memoryExtensionSelectionId ?? undefined,
      numberExistingBatteries,
      numberAdditionalBattery,
      numberSummaryBatteries,
      ...userStore.customer,
      ...internalObjectAddress,
      birdProtection,
      senecHeat,
      imageRoofPlan,
    };

    const validateError = validateProductProps(productsProps);

    // right area widget results
    const calculatorResult = calculator.getProducts(productsProps, [], {
      isHistory: false,
      showWallbox: shouldShowWallbox,
      showHotWater: shouldShowHotWater,
      showStorageExtension: shouldShowStorageExtension,
      showMemoryExtension: shouldShowMemoryExtension,
      showBlackoutBox: shouldShowBlackoutBox,
      showFinancing: shouldShowFinancing,
      showPhovoltaik: shouldShowPhovoltaik,
    });

    dataStore.setProducts(calculatorResult);
    // separated data by different sections
    const isPvChecked = featuresOptions.find((item) => item.url === pageUrl.photovoltaikQuote)?.checked ?? false;
    const isWaterPumpChecked = featuresOptions.find((item) => item.url === pageUrl.hotWaterPump)?.checked ?? false;
    const isWallboxChecked = featuresOptions.find((item) => item.url === pageUrl.wallboxQoute)?.checked ?? false;
    const isStorageChecked = featuresOptions.find((item) => item.url === pageUrl.storageExtension)?.checked ?? false;
    const isBlackoutBoxChecked = featuresOptions.find((item) => item.url === pageUrl.blackoutBox)?.checked ?? false;
    const isMemoryExtensionChecked = featuresOptions.find((item) => item.url === pageUrl.memoryExtension)?.checked ?? false;

    const { widgetData: pvWidgetData, errors: pvErrors } = getPvWidgetData(calculatorResult, userStore.quoteNumber, isPvChecked);
    const hotWaterWidgetData = getHotWaterWidgetData(calculatorResult, userStore.quoteNumber, isWaterPumpChecked);
    const { widgetData: wallboxWidgetData, errors: wallboxErrors } = getWallboxWidgetData(
      calculatorResult,
      userStore.quoteNumber,
      isWallboxChecked,
    );
    const { widgetData: storageExtensionWidgetData, errors: storageExtErrors } = getStorageExtensionData(
      calculatorResult,
      userStore.quoteNumber,
      isStorageChecked,
    );
    const blackoutBoxWidgetData = getBlackoutBoxWidgetData(calculatorResult, userStore.quoteNumber, isBlackoutBoxChecked);
    const { widgetData: memoryExtensionWidgetData, errors: memoryErrors } = getMemoryExtensionWidgetData(
      calculatorResult,
      userStore.quoteNumber,
      isMemoryExtensionChecked,
    );

    const summaryResult = getSummaryWidgetData(calculatorResult);

    const { financingValidation } = calculatorResult as ProductResultType;
    let financingErrors: string[] = [];

    if (financingValidation?.errors && Array.isArray(financingValidation.errors)) {
      financingErrors = financingValidation.errors.map(({ error }) => error) ?? [];
    }

    const errors = [...pvErrors, ...wallboxErrors, ...storageExtErrors, ...memoryErrors, ...financingErrors];

    if (validateError) {
      errors.push(validateError);
    }

    const filteredErrors = [...new Set(errors)];

    // const calculatedStorageValue = (calculatorResult as ProductResultType)?.pvProducts?.data?.find((prod) => prod.isStorage);

    // if (calculatedStorageValue && storageCalculated) {
    //   photovoltaikStore.setInternalCalculatedStorageValue(calculatedStorageValue.serviceId ?? null);
    //   dataStore.setProductsBasedOnStorage(calculatedStorageValue.serviceId ?? null);
    // }

    // separated sections baed on data
    const pvProductsData = getWidgetDataSection(pvWidgetData);
    const hotWaterProductsData = getWidgetDataSection(hotWaterWidgetData);
    const wallboxProductsData = getWidgetDataSection(wallboxWidgetData);
    const storageExtensionProducts = getWidgetDataSection(storageExtensionWidgetData);
    const blackoutBoxProductsData = getWidgetDataSection(blackoutBoxWidgetData);
    const memoryExtensionProductsData = getWidgetDataSection(memoryExtensionWidgetData);
    const summarySection = getSummaryWidgetDataSection(summaryResult);

    const updatedWidgetData: TWidgetSection[] = [];

    // push to widget UI if there is a data
    if (pvProductsData) {
      updatedWidgetData.push(pvProductsData);
    }
    if (hotWaterProductsData) {
      updatedWidgetData.push(hotWaterProductsData);
    }
    if (wallboxProductsData) {
      updatedWidgetData.push(wallboxProductsData);
    }
    if (storageExtensionProducts) {
      updatedWidgetData.push(storageExtensionProducts);
    }

    if (blackoutBoxProductsData) {
      updatedWidgetData.push(blackoutBoxProductsData);
    }

    if (memoryExtensionProductsData) {
      updatedWidgetData.push(memoryExtensionProductsData);
    }

    if (summarySection) {
      updatedWidgetData.push(summarySection);
    }

    if (filteredErrors.length) {
      dataStore.setIsGenerateQuote(false);
    } else {
      dataStore.setIsGenerateQuote(true);
    }

    return {
      widgetData: updatedWidgetData,
      productsProps,
      calculatorResult,
      calculator,
      errors: filteredErrors,
    };
  };

  return calculationValues();
};
