import i18n from '../i18n';
import DataStore from '../stores/DataStore';
import pageUrls from '../const/pages';
import { FinancingForm } from '../types/TGeneral';

export const MIN_INTEREST_RATE_HOUSE_BANK = 0.5;

export const MAX_INTEREST_RATE_HOUSE_BANK = 9;

// OPTIONS FOR paymentSelection
export const paymentSelectionOptions = [
  { serviceId: 'financing', name: 'Finanzierung', id: 'financing', label: 'Finanzierung' },
  {
    serviceId: 'leasingSelfEmployed',
    name: 'Leasing (nur für Selbstständige)',
    id: 'leasingSelfEmployed',
    label: 'Leasing (nur für Selbstständige)',
  },
  {
    serviceId: 'rentingSelfEmployed',
    name: 'Mietkauf (nur für Selbstständige)',
    id: 'rentingSelfEmployed',
    label: 'Mietkauf (nur für Selbstständige)',
  },
  {
    id: '',
    name: 'Keine Finanzierung (Factoring/Barkauf)',
    serviceId: 'factoring',
    label: 'Keine Finanzierung (Factoring/Barkauf)',
  },
];

// OPTIONS FOR financingVia
export const financingViaOptions = [
  { serviceId: 'financingViaHouseBank', name: 'Hausbank' },
  { serviceId: 'financingViaBsh', name: 'BSH' },
];

export const hirePurchaseTermValues = [36, 48, 54, 60, 72, 84, 96, 108, 120];

export const getPaymentOptions = () =>
  paymentSelectionOptions.map((option) => ({
    id: option.serviceId,
    label: option.name,
  }));

export const getFinancingViaOptions = () =>
  financingViaOptions.map((option) => ({
    id: option.serviceId,
    label: option.name,
  }));

export const getFeatureName = (str: string): string => str.split('.')[1];

export const getFeaturesOptions = (dataStore: DataStore) =>
  dataStore.formSwitchers
    .filter((feature) => feature.checked && feature.url !== pageUrls.financing && feature.url !== pageUrls.basicData)
    .map((feature) => ({
      id: getFeatureName(feature.labelTranslation),
      checked: feature.checked,
      value: i18n.t(feature.labelTranslation),
      url: feature.url,
    }));

export const FEATURES_NAMES_MAP_BY_URL = {
  [pageUrls.blackoutBox]: i18n.t('financingPage.bb'),
  [pageUrls.hotWaterPump]: i18n.t('forms.hotWaterPump'),
  [pageUrls.memoryExtension]: i18n.t('financingPage.me'),
  [pageUrls.photovoltaikQuote]: i18n.t('financingPage.pv'),
  [pageUrls.storageExtension]: i18n.t('financingPage.se'),
  [pageUrls.wallboxQoute]: i18n.t('financingPage.wb'),
};

export const updateByLeasingSelfEmployment = (formToUpdate: FinancingForm) => ({
  ...formToUpdate,
  leasingDownPayment: 0,
  leasingTerm: 96,
  leasingTermWp: 96,
  leasingTermWallbox: 96,
  leasingTermBlackoutBox: 96,
  leasingTermMemoryExtension: 96,
  leasingTermStorageExtension: 96,
  leasingDownPaymentWp: 0,
  hirePurchaseDownPaymentWp: undefined,
  hirePurchaseDownPayment: undefined,
  hirePurchaseTermWp: undefined,
  hirePurchaseTerm: undefined,
  hirePurchaseTermWallbox: undefined,
  hirePurchaseTermBlackoutBox: undefined,
  hirePurchaseTermMemoryExtension: undefined,
  hirePurchaseTermStorageExtension: undefined,
  financingDownPayment: undefined,
  financingDownPaymentWp: undefined,
  financingTerm: undefined,
  financingTermWp: undefined,
  financingTermWallbox: undefined,
  financingTermBlackoutBox: undefined,
  financingTermMemoryExtension: undefined,
  financingTermStorageExtension: undefined,
  financingVia: undefined,
});

export const updateByRentingSelfEmployment = (formToUpdate: FinancingForm) => ({
  ...formToUpdate,
  hirePurchaseDownPayment: 0,
  hirePurchaseDownPaymentWp: 0,
  hirePurchaseTerm: 36,
  hirePurchaseTermWp: 36,
  hirePurchaseTermWallbox: 36,
  hirePurchaseTermBlackoutBox: 36,
  hirePurchaseTermMemoryExtension: 36,
  hirePurchaseTermStorageExtension: 36,
  financingDownPayment: undefined,
  financingDownPaymentWp: undefined,
  financingTerm: undefined,
  financingTermWp: undefined,
  financingTermWallbox: undefined,
  financingTermBlackoutBox: undefined,
  financingTermMemoryExtension: undefined,
  financingTermStorageExtension: undefined,
  financingVia: undefined,
  leasingDownPayment: undefined,
  leasingDownPaymentWp: undefined,
  leasingTerm: undefined,
  leasingTermWp: undefined,
  leasingTermWallbox: undefined,
  leasingTermBlackoutBox: undefined,
  leasingTermMemoryExtension: undefined,
  leasingTermStorageExtension: undefined,
});

// Utility function to round numbers to two decimal places and avoid .00 for whole numbers
export const roundToTwoDecimals = (num: number): string => {
  // Check if the number is a whole number
  if (Math.floor(num) === num) {
    return num.toString(); // Return as a string without decimal points
  }

  // Return the number rounded to two decimal places
  return num.toFixed(2);
};
