/**
 * Created by Max Gornostayev 2/28/22
 *
 * MODEL - customer model
 */
import { UserType } from 'bsh-calculator';

import { ICustomer } from '../interfaces/IModels';

class Customer implements ICustomer {
  objectId?: string = '';

  firstName?: string = '';

  lastName?: string = '';

  email?: string = '';

  phone?: string = '';

  city?: string = '';

  country?: string = '';

  street?: string = '';

  streetNumber?: string = '';

  postcode?: string = '';

  crmContactId?: string = '';

  crmResponsibleUserId?: string = '';

  departmentId?: string = '';

  salutation: string = '';

  companyName?: string = '';

  companyCrmId?: number;

  type?: UserType = 'contact';
}

export default Customer;
